<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="6">
        <app-text-field class="mr-4" label="Data" solo flat background-color="grey lighten-4"
          v-model="params.date_start" type="date" />
        <app-text-field class="mr-4" label="Data" solo flat background-color="grey lighten-4" v-model="params.date_end"
          type="date" />
        <v-btn class="mr-4" color="primary" @click="select()">Pesquisar</v-btn>
      </v-col>
      <v-col>
        <v-chip-group v-model="params.statuses" multiple mandatory @change="select()">
          <v-chip filter outlined value="to_pay"> A Pagar </v-chip>
          <v-chip filter outlined value="paid"> Pago </v-chip>
          <v-chip filter outlined value="renegotiated"> Renegociado </v-chip>
          <v-chip filter outlined value="canceled"> Cancelado </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex" cols="6">
        <CompanySelect class="mr-4" :item-value="(item) => item.id" chips multiple v-model="params.company_id" solo flat
          background-color="grey lighten-4" :dense="false" @change="select()" />

        <SalesmanSearch background-color="grey lighten-4" solo flat :dense="false" label="Vendedor" hidePosition="true"
          @input="setSalesmanId($event)" clearable />
      </v-col>
    </v-row>

    <v-row class="mt-10 justify-space-between">
      <v-col>
        <v-card color="primary" elevation="0" dark>
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total
              <br />
              <b class="text-h4">{{ $format.decimal(billReceivableReport.sum_value) }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total a receber
              <br />
              <b class="text-h4">{{ $format.decimal(billReceivableReport.sum_to_pay) }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total Pago
              <br />
              <b class="text-h4">{{ $format.decimal(billReceivableReport.sum_paid_value) }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              PMR
              <br />
              <b class="text-h4">{{ billReceivableReport.pmr }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat color="grey lighten-4">
          <v-card-title>
            <span>Contas a receber por mês</span>
          </v-card-title>
          <v-card-text>
            <div>
              <apexchart width="100%" height="300px" type="bar" :options="chartOptions" :series="chartSeries">
              </apexchart>
            </div>
            <div id="line" class="mt-6">
              <v-data-table :headers="Headers" :items="items" hide-default-footer></v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat color="grey lighten-4">
          <v-card-title>
            <span>Pagamentos contas a receber</span>
          </v-card-title>
          <v-card-text>
            <div id="line" class="mt-6">
              <v-data-table :headers="paymentHeaders" :items="paymentItems" hide-default-footer></v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        Total geral
        Total vencido

        Total vencido 180 dias

        Inadimplencia 12Meses
        Prazo Médio de Pagamento 12Meses
      </v-col>
    </v-row> -->
    <!-- <v-row>
      <v-col cols=" 6">
        billReceivableReport
        <br>
        {{ billReceivableReport }}
      </v-col>

    </v-row>
    <v-row>
      <v-col>
        billReceivableByMonthReport
        <br>
        {{ billReceivableByMonthReport }}
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        billReceivablePaymentReport
        <br>
        {{ billReceivablePaymentReport }}
      </v-col>
    </v-row> -->

  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch.vue";

export default {
  components: {
    CompanySelect,
    SalesmanSearch
  },
  data: () => ({
    params: {
      company_id: [],
      salesman_id: [],
      date_start: format(new Date(), "yyyy-MM-01"),
      date_end: format(new Date(), "yyyy-MM-dd"),
      statuses: ['to_pay', 'paid']
    },

    billReceivableReport: {},

    billReceivableByMonthReport: [],

    billReceivablePaymentReport: [],

    chartSeries: [],

    chartOptions: {
      chart: {
        id: 'vuechart-example',
        type: 'bar',

        height: 400,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },

      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },

      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
          dataLabels: {

            total: {
              enabled: false,
              style: {
                fontSize: '12px',
                fontWeight: 500
              }
            }
          },
          columnWidth: '70%',
        },
      },

      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `R$ ${val.toFixed(2)}`;
        },
        offsetY: -20,
        position: 'center',
        style: {
          align: 'center',
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      xaxis: {
        categories: [],
        labels: {
          position: 'top',
          style: {
            align: 'center',
            colors: '#9aa0ac',
            fontSize: '12px',
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return `R$ ${val.toFixed(2)}`;
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {

        y: {
          formatter: function (val) {
            return `R$ ${val.toFixed(2)}`;
          },
        },
      },
      colors: ['#1E90FF', '#32CD32'],
    },
  }),

  mounted() {
    this.select();
  },

  computed: {
    Headers() {
      return [
        { text: "", value: "totals", align: "start", width: "1%", sortable: false },
        ...this.billReceivableByMonthReport.map((item) => {
          return {
            text: format(parseISO(item.isse_at_month), "MM/yyyy"),
            value: item.isse_at_month, width: "5%",
          };

        })
      ];
    },

    items() {
      return [
        {
          totals: "Total",
          ...this.billReceivableByMonthReport.reduce((acc, item) => {
            acc[item.isse_at_month] = this.$format.moneyBr(item.sum_value);
            return acc;
          }, {}),
        },
        {
          totals: "A receber",
          ...this.billReceivableByMonthReport.reduce((acc, item) => {
            acc[item.isse_at_month] = this.$format.moneyBr(item.sum_to_pay);
            return acc;
          }, {}),
        },
        {
          totals: "Pago",
          ...this.billReceivableByMonthReport.reduce((acc, item) => {
            acc[item.isse_at_month] = this.$format.moneyBr(item.sum_paid_value);
            return acc;
          }, {}),
        },
        {
          totals: "Inadimplência ",
          ...this.billReceivableByMonthReport.reduce((acc, item) => {
            acc[item.isse_at_month] = this.calculateInadimplencia(item) + " %";
            return acc;
          }, {}),
        },
      ];
    },

    paymentHeaders() {
      return [
        { text: "", value: "totals", align: "start", width: "1%", sortable: false },
        ...this.billReceivablePaymentReport.map((item) => {
          return {
            text: format(parseISO(item.paid_at_month), "MM/yyyy"),
            value: item.paid_at_month, width: "5%",
          };

        })
      ];
    },

    paymentItems() {
      return [
        {
          totals: "Valor Original",
          ...this.billReceivablePaymentReport.reduce((acc, item) => {
            acc[item.paid_at_month] = this.$format.moneyBr(item.paid_value_sum);
            return acc;
          }, {}),
        },

        {
          totals: "Juros",
          ...this.billReceivablePaymentReport.reduce((acc, item) => {
            acc[item.paid_at_month] = this.$format.moneyBr(item.interest_sum);
            return acc;
          }, {}),
        },
        {
          totals: "Juros %",
          ...this.billReceivablePaymentReport.reduce((acc, item) => {
            const interestPercentage = (parseFloat(item.interest_sum) / parseFloat(item.net_paid_value_sum)) * 100;
            acc[item.paid_at_month] = this.$format.decimal(interestPercentage) + ' %';
            return acc;
          }, {}),
        },
        {
          totals: "Desconto",
          ...this.billReceivablePaymentReport.reduce((acc, item) => {
            acc[item.paid_at_month] = this.$format.moneyBr(item.discount_sum);
            return acc;
          }, {}),
        },
        {
          totals: "Desconto %",
          ...this.billReceivablePaymentReport.reduce((acc, item) => {
            acc[item.paid_at_month] = this.calculateDiscountPercentage(item) + " %";
            return acc;
          }, {}),
        },
        {
          totals: "Valor Líquido",
          ...this.billReceivablePaymentReport.reduce((acc, item) => {
            acc[item.paid_at_month] = this.$format.moneyBr(item.net_paid_value_sum);
            return acc;
          }, {}),
        },
      ]
    }
  },

  methods: {
    select() {

      this.getBillReceivableReport();

      this.getBillReceivableReportByMonth();

      this.getBillReceivablePaymentReport()
    },

    getBillReceivableReport() {
      let params = {
        company_id: this.params.company_id,
        salesman_id: this.params.salesman_id,
        issue_date_start: this.params.date_start,
        issue_date_end: this.params.date_end,
        statuses: this.params.statuses
      };

      this.$http.index("bill-receivable/bill-receivable-report", params).then((response) => {
        this.billReceivableReport = response.billReceivableReport;

        const pmr = this.calculatePMR(this.billReceivableReport);

        this.$set(this.billReceivableReport, 'pmr', pmr);
      });
    },

    getBillReceivableReportByMonth() {
      let params = {
        company_id: this.params.company_id,
        salesman_id: this.params.salesman_id,
        issue_date_start: this.params.date_start,
        issue_date_end: this.params.date_end,
        statuses: this.params.statuses
      };

      this.$http.index("bill-receivable/bill-receivable-by-month-report", params).then((response) => {
        this.billReceivableByMonthReport = response.billReceivableByMonthReport;
        this.buildChart();
      });
    },

    getBillReceivablePaymentReport() {
      let params = {
        company_id: this.params.company_id,
        salesman_id: this.params.salesman_id,
        paid_at_start: this.params.date_start,
        paid_at_end: this.params.date_end,
        statuses: ['paid']
      };

      this.$http.index("bill-receivable/bill-receivable-payment-report", params).then((response) => {
        this.billReceivablePaymentReport = response.billReceivablePaymentReport;
      });
    },

    calculateDiscountPercentage(item) {
      const discount = parseFloat(item.discount_sum);

      const netPaidValue = parseFloat(item.paid_value_sum);

      if (netPaidValue > 0) {
        const value = discount / netPaidValue * 100;
        return this.$format.decimal(value);
      } else {
        return 0;
      }
    },

    calculatePeriod() {

      const startDate = new Date(this.params.date_start);

      const endDate = new Date(this.params.date_end);

      const period = Math.abs(endDate - startDate);

      const diffDays = Math.ceil(period / (1000 * 60 * 60 * 24));

      return diffDays;
    },

    calculatePMR(item) {

      const totalReceivable = parseFloat(item.sum_to_pay);

      const totalSales = parseFloat(item.sum_value);

      const period = this.calculatePeriod();

      if (totalSales > 0 && period > 0) {
        const pmr = totalReceivable / totalSales * period;

        return Math.round(pmr);
      } else {
        return 0;
      }

    },

    calculateInadimplencia(item) {

      const totalReceivable = parseFloat(item.sum_to_pay);

      const totalSales = parseFloat(item.sum_value);

      let inadimplencia = 0;

      if (totalSales > 0) {
        inadimplencia = totalReceivable / totalSales;

        return this.$format.decimal(inadimplencia * 100);
      } else {
        return 0;
      }
    },

    buildChart() {
      const categories = this.billReceivableByMonthReport.map(item => format(parseISO(item.isse_at_month), "MM/yyyy"));

      const sumToPay = this.billReceivableByMonthReport.map(item => parseFloat(item.sum_to_pay));

      const sumPaid = this.billReceivableByMonthReport.map(item => parseFloat(item.sum_paid_value));

      if (categories.length === 1) {
        this.chartOptions.plotOptions.bar.barHeight = '100%';
      }

      this.chartOptions.xaxis = {
        categories: categories,
      };

      this.chartSeries = [

        {
          name: 'Pago',
          data: sumPaid,
        },
        {
          name: 'A Receber',
          data: sumToPay,
        },
      ];
    },

    setSalesmanId(value) {

      this.params.salesman_id = value ? value.id : null
      this.select();
    },
  }
};
</script>

<style>
#line table {
  background-color: #f5f5f5;

  th:nth-child(n+2),
  td:nth-child(n+2) {
    text-align: right !important;
  }

  th,
  td {
    padding: 8px;
    margin: 0;
  }

}
</style>
